import { OktaAuth } from "@okta/okta-auth-js";

const CONFIG_STORAGE_KEY = "oktaClientConfig";
const AUTH_CODE_GRANT_TYPE = "authorization_code";

const responseTypes = {};
responseTypes[AUTH_CODE_GRANT_TYPE] = "code";

export default class OktaClient {
  oktaAuthConfig = {};

  constructor(authorize, clientId, redirectUri, loginHint) {
    this.oktaAuthConfig = {
      // Required config
      issuer: authorize,
      clientId: clientId,
      redirectUri,
      loginHint
    };

    this.buildClient(this.oktaAuthConfig);
  }

  /**
   * Check if the browser supports PKCE
   * @returns { boolean }
   */
  static isPKCESupported() {
    return OktaAuth.features.isPKCESupported();
  }

  /**
   * Restore okta client from storage
   * @returns { OktaClient }
   */
  static restoreFromStorage() {
    const existingConfig = JSON.parse(
      sessionStorage.getItem(CONFIG_STORAGE_KEY)
    );
    if (existingConfig === null) {
      throw "No config found";
    }

    const newClient = new OktaClient(
      existingConfig.issuer,
      existingConfig.clientId,
      existingConfig.redirectUri,
      existingConfig.loginHint
    );

    return newClient;
  }

  /**
   * Build Okta auth client
   * @param { Object } config
   */
  buildClient(config) {
    this.authClient = new OktaAuth({
      // Required config
      ...config,

      // etc...
      responseType: "token"
    });
  }

  /**
   * Save the current auth client config
   * @returns { OktaClient }
   */
  saveConfig() {
    sessionStorage.setItem(
      CONFIG_STORAGE_KEY,
      JSON.stringify(this.oktaAuthConfig)
    );
    return this;
  }

  /**
   * Remove the cached config
   * @returns { OktaClient }
   */
  clearConfig() {
    sessionStorage.setItem(CONFIG_STORAGE_KEY, null);
    return this;
  }

  /**
   * Begin the auth process
   */
  start() {
    const grantType = AUTH_CODE_GRANT_TYPE;

    this.authClient.options.grantType = grantType;
    this.authClient.token.getWithRedirect({
      responseType: responseTypes[grantType],
      scopes: ["openid", "profile", "email", "offline_access"]
    });
  }

  /**
   * Get access token & refresh token after auth
   * @returns { Promise }
   */
  getToken() {
    return new Promise((resolve, reject) => {
      if (window.location.href.indexOf("code=") < 0) {
        reject({
          message: "Unsupported grant type"
        });
      }

      this.authClient.token
        .parseFromUrl()
        .then(tokens => {
          resolve(tokens.tokens);
        })
        .catch(reject);
    });
  }
}
